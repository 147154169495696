import React from 'react';

// interfaces
import { IHomePageProps, IHomePageState } from './models';

// components
import { CrisisGuide } from 'components/CrisisGuide';
import { HelpInfo } from 'components/HelpInfo';
import { MainHero } from 'components/MainHero';
import { StartBanner } from 'components/StartBanner';
import { CrisisModal } from 'components/CrisisModal';

/** @container */
export class HomePage extends React.Component<IHomePageProps, IHomePageState> {
    state: IHomePageState = {
        isCrisisModalActive: false,
    };

    /** @helpers */

    showCrisisModal = (): void => {
        this.setState({ isCrisisModalActive: true });
    };

    hideCrisisModal = (): void => {
        this.setState({ isCrisisModalActive: false });
    };

    /** @render */

    render(): React.ReactNode {
        const { isCrisisModalActive } = this.state;
        return (
            <div className="home">
                <MainHero />
                <CrisisGuide onClick={this.showCrisisModal} />
                {/* info components, left and right side */}
                <HelpInfo type="left-side" withBtn>
                    <h1>Helping parents and caregivers advocate for their child&apos;s well-being</h1>
                    <p>
                        Suspecting your child has a behavioral and/or mental health condition can be overwhelming.
                        Families often feel lost. We want to change that. <br />
                        <br />
                        We&apos;re here to help you get the best care for your child by providing information on how to
                        navigate the healthcare system: who to talk to, what to ask, and what to expect from the
                        process.
                    </p>
                </HelpInfo>
                <HelpInfo type="right-side">
                    <h1>Guidance developed by pediatric healthcare professionals in partnership with parents</h1>
                    <p>
                        This pilot site is designed to help families with children between the ages of 3-13 who are
                        seeking support for managing suspected or confirmed Autism Spectrum Disorder (ASD) or Major
                        Depressive Disorder (MDD).
                        <br />
                        <br /> If this describes you and your child, click Start Here to begin assembling your
                        personalized resource package. <br />
                        <br />
                        If this does not describe your situation, please check back in a few months as this pilot
                        expands.
                    </p>
                </HelpInfo>
                {/* start(yellow) banner component */}
                <StartBanner />
                <CrisisModal show={isCrisisModalActive} onHide={this.hideCrisisModal} />
            </div>
        );
    }
}
