import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// interfaces
import { IButtonProps } from './models';

export const Button = (props: IButtonProps): JSX.Element => {
    const { label, type, withLink, onClick } = props;
    const btnClasses = classnames({
        button: true,
        [`button--${type}`]: type,
    });
    return (
        <>
            {withLink ? (
                <Link className={btnClasses} to={withLink}>
                    {label}
                </Link>
            ) : (
                <button className={btnClasses} onClick={onClick}>
                    {label}
                </button>
            )}
        </>
    );
};
