import React from 'react';
import classnames from 'classnames';

// interfaces
import { IHelpInfoProps } from './models';

// components
import { Button } from 'components/Button';

export const HelpInfo = (props: IHelpInfoProps): JSX.Element => {
    const { type, withBtn, children } = props;

    const containerClasses = classnames({
        'help-info': true,
        [`help-info--${type}`]: type,
    });
    const childrenArray = React.Children.toArray(children);
    const childrenCount = React.Children.count(children);
    return (
        <div className={containerClasses}>
            {childrenCount === 2 && (
                <div className="help-info__wrapper">
                    <div className="help-info__title">
                        {childrenArray[0]}
                        {withBtn && (
                            <div className="help-info__btn">
                                <Button label="Start Here" withLink="/action-plan" />
                            </div>
                        )}
                    </div>
                    <div className="help-info__desc">{childrenArray[1]}</div>
                </div>
            )}
        </div>
    );
};
