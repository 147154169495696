import React from 'react';

// component
import { Button } from 'components/Button';

export const MainHero = (): JSX.Element => {
    return (
        <div className="hero">
            <div className="hero__wrapper">
                <div className="hero__info-block">
                    <div className="hero__title">
                        <h1>Support that fits your family</h1>
                    </div>
                    <p className="hero__desc">
                        Resources and a personalized, step-by-step action plan for families and caregivers seeking
                        mental and behavioral health care for their children.
                    </p>
                    <div className="hero__btn">
                        <Button label="Start here" withLink="/action-plan" />
                    </div>
                </div>
            </div>
        </div>
    );
};
