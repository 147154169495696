import React from 'react';

// interfaces
import { IModalProps } from './models';

// svg
import pdfIcon from '../../assets/svg/pdf.svg';
import closeIcon from '../../assets/svg/close.svg';

// components
import Modal from 'react-bootstrap/Modal';
import { Button } from 'components/Button';

export const CrisisModal = (props: IModalProps): JSX.Element => {
    const { show, onHide } = props;

    return (
        <>
            <Modal show={show} onHide={onHide} animation={false}>
                <div className="modal__header">
                    <h2 className="modal__title">Crisis plan</h2>
                    <p className="modal__desc">
                        If you child is in immediate danger of harming themself or others, use the chart below to
                        determine your next step.
                    </p>
                </div>
                <div className="modal__info-wrapper">
                    <div className="modal__info-heading">
                        <p>Your child’s situation</p>
                        <p>When to get help</p>
                        <p>Where to get help</p>
                    </div>
                    <div className="modal__info-container modal__info-container--red">
                        <ul>
                            <li>Your child is showing severe, out-of-control behavior.</li>
                            <li>Your child may be a threat to self or others.</li>
                            <li>You are frightened.</li>
                        </ul>
                        <p>
                            Immediately
                            <br /> (emergency)
                        </p>
                        <ul>
                            <li>911</li>
                            <li>Hospital emergency </li>
                        </ul>
                    </div>
                    <div className="modal__info-container modal__info-container--yellow">
                        <ul>
                            <li>There has been a major change in your child’s behavior.</li>
                            <li>Your child seems unable to function without help.</li>
                            <li>You feel unable to cope with the situation or help your child.</li>
                        </ul>
                        <p>
                            48-72 hours
                            <br /> (urgent)
                        </p>
                        <ul>
                            <li>Pediatrician</li>
                            <li>Mental health specialist</li>
                            <li>School</li>
                        </ul>
                    </div>
                    <div className="modal__info-container modal__info-container--aqua">
                        <ul>
                            <li>There has been a mild to moderate change in your child’s behavior.</li>
                            <li>You are worried but not alarm.</li>
                        </ul>
                        <p>Soon (a routine evaluation)</p>
                        <ul>
                            <li>Pediatrician</li>
                            <li>Mental health specialist</li>
                            <li>School</li>
                            <li>Family or friend</li>
                            <li>Community organization</li>
                        </ul>
                    </div>
                </div>
                <div className="modal__controls">
                    <Button label="Close" type="close" onClick={onHide} />
                    <a href="/pdfs/Crisis_plan.pdf" download className="modal__download">
                        <img src={pdfIcon} alt="pdf-icon" />
                        <p>Download</p>
                    </a>
                </div>
                <div className="modal__close-btn" onClick={onHide}>
                    <img src={closeIcon} alt="close-icon" />
                </div>
            </Modal>
        </>
    );
};
