import React from 'react';

// components
import { Button } from 'components/Button';

// interfaces
import { ICrisisGuideProps } from './models';

export const CrisisGuide = (props: ICrisisGuideProps): JSX.Element => {
    const { onClick } = props;
    return (
        <div className="crisis-guide">
            <div className="crisis-guide__container">
                <p className="crisis-guide__info-text big-text">Is your child in crisis?</p>
                <Button label="Child Crisis Guide" type="crysis" onClick={onClick} />
            </div>
        </div>
    );
};
