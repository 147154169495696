import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// icons
import emailIcon from '../../assets/svg/email.svg';
import hamburgIcon from '../../assets/svg/hamburg_menu.svg';

export const Header = (): JSX.Element => {
    const [toggle, setToggle] = useState(false);

    return (
        <div className="header">
            <div className="header__wrapper">
                <div className="header__logo">
                    <p className="header__logo-title">
                        <Link to={'/'}>guide</Link>
                    </p>
                </div>
                <div className="header__header-links">
                    <a href="#" id="header--link">
                        About
                    </a>
                    <Link id="header--link" to={'/action-plan'}>
                        Action Plan
                    </Link>
                    <div className="mobile--nav--options">
                        <img
                            onClick={() => setToggle(!toggle)}
                            src={hamburgIcon}
                            className="hamburg--icon-header"
                            alt="hamburg-icon"
                        />
                        {toggle && (
                            <div className="open-hamburg">
                                <a href="#">About</a>
                                <Link to={'/action-plan'}>Action Plan</Link>
                            </div>
                        )}
                        <a href="mailto:FSPLEADBOX@otpum.com">
                            <div className="oval--icon-header">
                                <img src={emailIcon} className="email--icon-header" alt="email-icon" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
