import React from 'react';

// components
import { Button } from 'components/Button';

export const StartBanner = (): JSX.Element => {
    return (
        <div className="start-banner">
            <div className="start-banner__container">
                <p className="big-text">Create an action plan that&apos;s personalized to you.</p>
                <br />
                <p className="big-text">No sign up or email required.</p>
                <div className="start-banner__btn">
                    <Button label="Start here" withLink="/action-plan" />
                </div>
            </div>
        </div>
    );
};
