import React from 'react';
import { Link } from 'react-router-dom';

// icons
import emailIcon from '../../assets/svg/email.svg';

export const Footer = (): JSX.Element => {
    return (
        <div className="footer">
            <div className="footer__wrapper">
                <div className="footer__copyright-statement">
                    <p>©2021 Optum, Inc. All rights reserved.</p>
                </div>
                <div className="footer__footer-links">
                    <a href="#">About</a>
                    <Link id="action-plan" to={'/action-plan'}>
                        Action Plan
                    </Link>
                    <div className="footer-links__contact">
                        <a href="mailto:FSPLEADBOX@otpum.com">
                            <div className="rectangle--message">
                                <p>Contact Optum Care Advocate</p>
                            </div>
                            <div className="oval--icon-footer">
                                <img src={emailIcon} className="email--icon-footer" alt="email-icon" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
