import React from 'react';

// interfaces
import { IActionPlanPageProps, IActionPlanPageState } from './models';

/** @container */
export class ActionPlanPage extends React.Component<IActionPlanPageProps, IActionPlanPageState> {
    /** @render */

    render(): React.ReactNode {
        return <div className="action-plan">Action Plan Page</div>;
    }
}
