import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

// containers & components
import { HomePage } from 'containers/HomePage';
import { ActionPlanPage } from 'containers/ActionPlanPage';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';

/** @App root */
const App = (): JSX.Element => {
    return (
        <BrowserRouter>
            <div className="app">
                <Header />
                <Route path="/" exact component={HomePage} />
                <Route path="/action-plan" component={ActionPlanPage} />
                <Footer />
            </div>
        </BrowserRouter>
    );
};

export default App;
